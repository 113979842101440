(function($) {

  /* トップページ */
  if(document.body.classList.contains('home')) {
    var mySwiper = new Swiper('.swiper-container', {
      speed: 400,
      spaceBetween: 0,
      effect: 'fade',
      loop: true,
      autoplay: {
        delay: 5000,
      },
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
    });
  }

  /* マップ */
  var map = document.querySelector('.js-map');
  if(map) {
    var areas = document.querySelectorAll('.js-map area');
    areas.forEach((area) => {
      // area.addEventListener('mouseover', function(area) {
      //   onMapHover(e);
      // })
      $(area).hover(onMapHover, onMapOut);
      // area.addEventListener('onmouseover', onMapHover, false);
      // area.addEventListener('onmouseout', onmouseout, false);
    });
  }

})( jQuery );

function onMapHover(event) {
  var e = event.target;
  var target = document.querySelector('.'+e.getAttribute('data-target'));
  if(target) { target.classList.add('is-show'); }
}

function onMapOut(event) {
  var e = event.target;
  var target = document.querySelector('.'+e.getAttribute('data-target'));
  if(target) { target.classList.remove('is-show'); }
}